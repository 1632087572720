import axios from 'axios'
// import errorCode from './errorCode.js'
import {
	baseURL
} from './config';
// import store from '@/store/index.js';
import {
	Message
} from 'element-ui';

import router from '@/router/index.js'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// axios.defaults.headers['user_token'] = '020ed465-1df7-7852-5738-b7bbeb9dd358';


// 创建axios实例
const service = axios.create({
	// axios中请求配置有baseURL选项，表示请求URL公共部分
	baseURL: baseURL,
	// 超时
	timeout: 20000
})
// request拦截器，用于加token
service.interceptors.request.use(config => {
	if (window.localStorage.getItem('user_token')) {
		let token = window.localStorage.getItem('user_token');
		console.log(token);
		config.headers['user-token'] = token;
		// axios.defaults.headers['user_token'] = token
	}else{
		console.log('11111111111');
	}

	// console.log(config)
	// config.headers.common['lang'] = localStorage.getItem("lang") == 'zh' ? 'zh' : 'sp';
	// config.headers.common['lang'] = 'zh';
	return config
}, error => {
	console.log(error)
	Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
		// 未设置状态码则默认成功状态
		const code = res.data.code || 200;
		const that = this
		// const status = res.status;
		// 获取错误信息
		// const msg = errorCode[code] || res.data.msg || errorCode['default'] || res.error || res.data.res;
		//获取错误提示
		// const ress = res.data.res;
		if (code === '1') {
			return res.data
		} else if (code == 0) {
			return res.data
		} else if (code == 2 || code == -1 || code == -999 || code == -900) {
			return res.data;
		} else if (code == '-201') {
			console.log('接口失败')
			// localStorage.removeItem("user_token");
			// 存储缓存中
			// this.$message.error('please log in first')
			window.localStorage.clear()
			window.location.href = '/'
			// const link = document.createElement('a');
			// link.href = '/homeIndex';
			// Message.error(res.msg);
			// that.$router.push({
			// 	path: "/homeIndex",
			// 	query: {
			// 		index: 1
			// 	}
			// })
			return Promise.reject(res.msg)

		} else {
			console.log('接口出问题')
			Message.error(res.msg);
			window.localStorage.clear()
			that.$router.push({
				path: "/homeIndex",
				query: {
					index: 1
				}
			})
			return Promise.reject(res.msg)

			// Message.error(res.data.msg);
			// router.push({
			// 	path: "/accountLogin"
			// });
			return Promise.reject(res.msg)
		}
	},
	error => {
		console.log('接口错误')
		console.log('err' + error);
		window.localStorage.clear()
		return Promise.reject(error)
	}
)

export default service