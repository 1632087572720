import request from '@/common/utils/request';
// 登录
export function loginUser(data) {
    return request({
        url: '5c78dbfd977cf ',
        method: 'post',
        data: data
    })
}

// 物品类型
export function articleType(data) {
    return request({
        url: '659df4e350f0f ',
        method: 'post',
        data: data
    })
}

// 查询物品规格
export function specificationers(data) {
    return request({
        url: '65ab2814997ad ',
        method: 'post',
        data: data
    })
}

// 提交订单
export function submitOrder(data) {
    return request({
        url: '62c8038d96dab ',
        method: 'post',
        data: data
    })
}

// 支付
export function payAction(data) {
    return request({
        url: '65ade5039d8c8 ',
        method: 'post',
        data: data
    })
}

// 计算距离
export function calculateDistance(data) {
    return request({
        url: '65af76a288348 ',
        method: 'post',
        data: data
    })
}

// 计算运费
export function calculateCosts(data) {
    return request({
        url: '65af78b55cab6 ',
        method: 'post',
        data: data
    })
}

// 获取会员详细信息
export function userInfomation(data) {
    return request({
        url: '5c78c4772da97',
        method: 'post',
        data: data
    })
}

// 修改用户资料 5cb54af125f1c
export function modifyingUserInfor(data) {
    return request({
        url: '5cb54af125f1c',
        method: 'post',
        data: data
    })
}

// 获取订单列表
export function orderList(data) {
    return request({
        url: '62c8f31da5eaa',
        method: 'post',
        data: data
    })
}

// 发送验证码
export function emailCode(data) {
    return request({
        url: '5b5bdc44796e8',
        method: 'post',
        data: data
    })
}

// 绑定手机号码
export function bindPhone(data) {
    return request({
        url: '5d7757d28d076',
        method: 'post',
        data: data
    })
}

// 校验验证码
export function verificationCode(data) {
    return request({
        url: '5f6db4db8abcf',
        method: 'post',
        data: data
    })
}

// 修改手机号
export function changePhoneNumber(data) {
    return request({
        url: '5f6c915d69d1f',
        method: 'post',
        data: data
    })
}

// 重置密码（忘记密码）
export function forgetPassword(data) {
    return request({
        url: '5caeeba9866aa',
        method: 'post',
        data: data
    })
}

// 注销账号原因
export function cancellationRea(data) {
    return request({
        url: '637c458b131e3',
        method: 'post',
        data: data
    })
}

// 发起注销
export function initiateCancellation(data) {
    return request({
        url: '637c4d70d3aa8',
        method: 'post',
        data: data
    })
}

// 协议
export function agreement(data) {
    return request({
        url: '641568f1b6f87',
        method: 'post',
        data: data
    })
}

// 取消订单
export function cancelOrder(data) {
    return request({
        url: '62cb8b8438141',
        method: 'post',
        data: data
    })
}

//公告列表
export function gongGaoList(data) {
    return request({
        url: '6415752f56d0f',
        method: 'post',
        data: data
    })
}

// 订单评价
export function evaluateOrderSta(data) {
    return request({
        url: '64218d79d9401',
        method: 'post',
        data: data
    })
}

// 查询售后原因
export function getRefundreason(data) {
    return request({
        url: '641ae6544d2d5',
        method: 'post',
        data: data
    })
}

// 提交售后
export function refundApply(data) {
    return request({
        url: '64219b8235a5e',
        method: 'post',
        data: data
    })
}

// 售后订单列表
export function refund_list(data) {
    return request({
        url: '6427d125edfc5',
        method: 'post',
        data: data
    })
}

// 订单详情
export function get_order_detail(data) {
    return request({
        url: '62c92b9d5ada3',
        method: 'post',
        data: data
    })
}

// 删除订单
export function deleteOrder(data) {
    return request({
        url: '6447c3b69f234',
        method: 'post',
        data: data
    })
}

// 免支付(测试用）
export function balance_pay(data) {
    return request({
        url: '65ade5039d8c8',
        method: 'post',
        data: data
    })
}

// 公告列表详情
export function getNoticeInfo(data) {
    return request({
        url: '641576c24de11',
        method: 'post',
        data: data
    })
}

// 支付方式
export function get_paytypelist(data) {
    return request({
        url: '64424fe115688',
        method: 'post',
        data: data
    })
}

// paypal支付接口
export function paypal_pay(data) {
    return request({
        url: '6322d6976482c',
        method: 'post',
        data: data
    })
}

// 系统配置-联系我们
export function getContactUs(data) {
    return request({
        url: '64534cbb2c352',
        method: 'post',
        data: data
    })
}

//公告消息
export function getMessageTips(data) {
    return request({
        url: '6424235abdc3c',
        method: 'post',
        data: data
    })
}

//联系我们的留言
export function set_contactus(data) {
    return request({
        url: '661250f9667bd',
        method: 'post',
        data: data
    })
}