import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const homeIndex = () =>
	import( /* webpackChunkName: "homeIndex" */ '@/views/homeIndex/homeIndex.vue') //首页
const placeOrder = () =>
	import( /* webpackChunkName: "homeIndex" */ '@/views/placeOrder/placeOrder.vue') //在线下单
const paySuccessful = () =>
	import( /* webpackChunkName: "paySuccessful" */ '@/views/paySuccessful/paySuccessful.vue') //支付成功
const aboutUs = () =>
	import( /* webpackChunkName: "aboutUs" */ '@/views/aboutUs/aboutUs.vue') //关于我们
const messagePage = () =>
	import( /* webpackChunkName: "messagePage" */ '@/views/messagePage/messagePage.vue') //消息
const mineIndex = () =>
	import( /* webpackChunkName: "mineIndex" */ '@/views/mineIndex/mineIndex.vue') //我的
const setUp = () =>
	import( /* webpackChunkName: "setUp" */ '@/views/setUp/setUp.vue') //账号安全
const modifyMobilePhone = () =>
	import( /* webpackChunkName: "modifyMobilePhone" */ '@/views/modifyMobilePhone/modifyMobilePhone.vue') //修改手机号-验证
const modifyTwoPhone = () =>
	import( /* webpackChunkName: "modifyTwoPhone" */ '@/views/modifyTwoPhone/modifyTwoPhone.vue') //修改手机号-绑定手机
const bindPhoneSuccess = () =>
	import( /* webpackChunkName: "bindPhoneSuccess" */ '@/views/bindPhoneSuccess/bindPhoneSuccess.vue') //修改手机号-换绑成功
const emailVerification = () =>
	import( /* webpackChunkName: "emailVerification" */ '@/views/emailVerification/emailVerification.vue') //修改邮箱号-验证
const modifyEmailNum = () =>
	import( /* webpackChunkName: "modifyEmailNum" */ '@/views/modifyEmailNum/modifyEmailNum.vue') //修改邮箱号-换绑手机
const bindEmailSuccess = () =>
	import( /* webpackChunkName: "bindEmailSuccess" */ '@/views/bindEmailSuccess/bindEmailSuccess.vue') //修改邮箱号-换绑成功
const modifyLoginPassword = () =>
	import( /* webpackChunkName: "modifyLoginPassword" */ '@/views/modifyLoginPassword/modifyLoginPassword.vue') //修改登录密码
const modifySetPassword = () =>
	import( /* webpackChunkName: "modifySetPassword" */ '@/views/modifySetPassword/modifySetPassword.vue') //修改登录密码-设置密码
const bindPasswordSuccess = () =>
	import( /* webpackChunkName: "bindPasswordSuccess" */ '@/views/bindPasswordSuccess/bindPasswordSuccess.vue') //修改登录密码-改密成功
const accountCancellation = () =>
	import( /* webpackChunkName: "accountCancellation" */ '@/views/accountCancellation/accountCancellation.vue') //注销账号
const reasonCancellation = () =>
	import( /* webpackChunkName: "reasonCancellation" */ '@/views/reasonCancellation/reasonCancellation.vue') //注销账号原因
const cancellationRisk = () =>
	import( /* webpackChunkName: "cancellationRisk" */ '@/views/cancellationRisk/cancellationRisk.vue') //注销账号风险
const unregisterSubmission = () =>
	import( /* webpackChunkName: "unregisterSubmission" */ '@/views/unregisterSubmission/unregisterSubmission.vue') //注销账号提交
	const contactUs = () =>
		import( /* webpackChunkName: "contactUs" */ '@/views/contactUs/contactUs.vue') //联系我们
const routes = [{
	path: '/',
	redirect: '/homeIndex',
}, {
	path: '/homeIndex',
	name: 'homeIndex',
	component: homeIndex
}, {
	path: '/placeOrder',
	name: 'placeOrder',
	component: placeOrder,
}, {
	path: '/paySuccessful',
	name: 'paySuccessful',
	component: paySuccessful
}, {
	path: '/aboutUs',
	name: 'aboutUs',
	component: aboutUs
}, {
	path: '/messagePage',
	name: 'messagePage',
	component: messagePage
}, {
	path: '/mineIndex',
	name: 'mineIndex',
	component: mineIndex
}, {
	path: '/setUp',
	name: 'setUp',
	component: setUp
}, {
	path: '/modifyMobilePhone',
	name: 'modifyMobilePhone',
	component: modifyMobilePhone
}, {
	path: '/modifyTwoPhone',
	name: 'modifyTwoPhone',
	component: modifyTwoPhone
}, {
	path: '/bindPhoneSuccess',
	name: 'bindPhoneSuccess',
	component: bindPhoneSuccess
}, {
	path: '/emailVerification',
	name: 'emailVerification',
	component: emailVerification
}, {
	path: '/modifyEmailNum',
	name: 'modifyEmailNum',
	component: modifyEmailNum
}, {
	path: '/bindEmailSuccess',
	name: 'bindEmailSuccess',
	component: bindEmailSuccess
}, {
	path: '/modifyLoginPassword',
	name: 'modifyLoginPassword',
	component: modifyLoginPassword
}, {
	path: '/modifySetPassword',
	name: 'modifySetPassword',
	component: modifySetPassword
}, {
	path: '/bindPasswordSuccess',
	name: 'bindPasswordSuccess',
	component: bindPasswordSuccess
}, {
	path: '/accountCancellation',
	name: 'accountCancellation',
	component: accountCancellation
}, {
	path: '/reasonCancellation',
	name: 'reasonCancellation',
	component: reasonCancellation
}, {
	path: '/cancellationRisk',
	name: 'cancellationRisk',
	component: cancellationRisk
}, {
	path: '/unregisterSubmission',
	name: 'unregisterSubmission',
	component: unregisterSubmission
}, {
	path: '/contactUs',
	name: 'contactUs',
	component: contactUs
}]
const router = new VueRouter({
	mode: 'hash',
	//     // base:'/',    
	routes
})
export default router