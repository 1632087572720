import 'element-ui/lib/theme-chalk/index.css';
import Vue from 'vue'
import ElementUI from 'element-ui';
import * as http from '@/common/utils/http.js';
import axios from 'axios';
import App from './App.vue'
import router from './router'
import { base64TofileUpload } from "@/common/utils/upload.js";
import locale from '../node_modules/element-ui/lib/locale/lang/en'
ElementUI.Dialog.props.lockScroll.default = false;
// 设置英文
Vue.use(ElementUI, {locale})
Vue.config.productionTip = false;
Vue.prototype.base64TofileUpload = base64TofileUpload;
Vue.prototype.$axios = axios;
Vue.prototype.$http = http;
Vue.prototype.$EventBus = new Vue()

router.afterEach(()=>{
    window.scrollTo(0,0);
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')