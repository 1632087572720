<template>
	<div class="footer_content">
		<div class="top_fo_con">
			<div class="left_con_top">
				<div @click="toAboutUs">About Us</div>
				<div @click="openUserAgree">User Agreement</div>
				<div @click="openProvacy">Privacy Policy</div>
				<div @click="toContactUs">Contact Us</div>
			</div>
			<div class="right_con_top">
				<div class="top_img_wor">
					<img src="../assets/img/piggy2@2x.png" alt="">
					<div>{{footInfo.web_company_name}}</div>
				</div>
				<div class="info_bis bis-Io">
					<img src="../assets/img/location.png" alt="">
					<div style="display: flex;">Address: {{footInfo.web_site_address}}</div>
				</div>
				<div class="info_bis">
					<img src="../assets/img/tel.png" alt="">
					<div>Tel: {{footInfo.tel}}</div>
				</div>
				<div class="info_bis">
					<img src="../assets/img/mes.png" alt="">
					<div>Email: {{footInfo.web_site_email}}</div>
				</div>
			</div>
		</div>
		<div class="bottom_fo_con">
			<div>Version Number: {{footInfo.web_site_copyright}}</div>
			<!-- <div>Record number: Beijing ICP 2022000888</div> -->
			<div>Company Address：{{footInfo.web_site_address}}</div>
			<!-- <text style="width: 200px;">{{footInfo.web_site_address}}</text> -->
		</div>
		<el-dialog :visible.sync="agreementDialog" width="750px" custom-class="agreementCustomWidth">
			<div class="flex_center userRegi_title">User registration protocol</div>
			<div class="agreement_con">
				<!-- <div class="lastNum">Last updated 2022-06-22</div> -->
				<div class="agreement_sty" v-html="xieyiContent"></div>
			</div>
		</el-dialog>
		<!-- 隐私政策 -->
		<el-dialog :visible.sync="policyDialog" width="750px" custom-class="agreementCustomWidth">
			<div class="flex_center userRegi_title">Privacy Policy</div>
			<div class="agreement_con">
				<!-- <div class="lastNum">Last updated 2022-06-22</div> -->
				<div class="agreement_sty" v-html="xieyiContent"></div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'footerPage',
		data() {
			return{
				agreementDialog:false,
				policyDialog:false,
				column_id:'',
				xieyiContent:'',
				footInfo:{}
			}
		},
		created() {
			this.getConInfo()
		},
		methods:{
			getConInfo(){
				this.$http.getContactUs({
					code:'base'
				}).then(res=>{
					if(res.code == 1){
						this.footInfo = res.data
					}
				})
			},
			getXieyi(){
				this.$http.agreement({
					column_id:this.column_id
				}).then(res=>{
					if(res.code == 1){
						this.xieyiContent = res.data.content
					}
				})
			},
			toAboutUs(){
				if(!window.localStorage.getItem('user_token')){
					this.$message.info('please log in first')
					this.$emit("loginTigger", "触发登录")
					return false
				}
				this.$router.push({
					path: "/aboutUs",
					query: {
						index: 3
					}
				})
			},
			toContactUs(){
				if(!window.localStorage.getItem('user_token')){
					this.$message.info('please log in first')
					this.$emit("loginTigger", "触发登录")
					return false
				}
				this.$router.push({
					path: "/contactUs",
					query: {
						index: 10
					}
				})
			},
			openUserAgree(){
				this.column_id = 1
				this.getXieyi()
				this.agreementDialog = true
			},
			openProvacy(){
				this.column_id = 2
				this.getXieyi()
				this.policyDialog = true
			}
		}
	}
</script>

<style scoped>
	@import "../assets/css/footer.css"
</style>