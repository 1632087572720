import request from '@/common/utils/request';
export function base64TofileUpload(fileobj) {
  let param = new FormData();
  // 上传文件对象 名称file与后台控制器参数要一致
  param.append("file", fileobj);
  return request({
    method: "post",
    // 上传地址
    url: "https://piggyexpress.henanyimu.com/api/m5174/6481977a6298c",
    // 定义上传头
    headers: { "Content-Type": "multipart/form-data" },
    data: param
  });
}
