<template>
	<div class="header_content">
		<div v-if="indexItem == 1" class="header_img" :style="{backgroundImage: 'url('+bgImageOne+')'}"></div> <!-- 首页header背景 -->
		<div v-else class="header_background"></div> <!-- 首页header背景 -->
		<div class="header_bottom_solid"></div>
		<div class="header_container">
			<div class="left_container">
				<img src="../assets/img/piggy2@2x.png" alt="" class="header_logo">
				<div class="img_words" @click="goHome">PiggyExpress</div>
				<div class="segmentation"></div>
				<div class="tab_con">
					<div class="flex_center btn_ot" @click="btnClick(1)">
						<div>Home</div>
						<span class="bott_line" v-if="indexItem == 1"></span>
					</div>
					<div class="flex_center btn_identifying" @click="btnClick(2)">
						<div>Place Order</div>
						<span class="bott_line" v-if="indexItem == 2"></span>
						<div class="hot_sp">HOT</div>
					</div>
				</div>
			</div>
			<div class="right_container">
				<div class="messages_info" @click="goMessagePage">
					<img src="../assets/img/Icon@2x11.png" alt="">
					<div class="mes_word">Messages</div>
					<span v-if="xiaoxitishiHidden" class="tishihongdian"></span>
				</div>
				<div class="login_c flex_ali" @click="toMineIndex" v-if="is_sign">
					<img :src="userInfoVal.avatar" alt="">
					<el-dropdown>
					  <div class="name_userBer">{{userInfoVal.nickname}}</div>
					  <el-dropdown-menu slot="dropdown">
					    <el-dropdown-item>
							<span @click="singOut">Sign Out</span>
						</el-dropdown-item>
					  </el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="flex_ali" @click="logiSign" v-if="!is_sign">
					<span>Sign in</span>
					<span class="slash">/</span>
					<span>Sign up</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'headerPage',
		props: {
			// userMessage: {
			// 	type: Object,
			// 	default: "",
			// },
		},
		data() {
			return {
				indexItem: 1,
				userInfoVal:{},
				isLoading:true,
				is_sign:false,
				bgImageOne:'',
				xiaoxitishiHidden:false,
			}
		},
		mounted() {
			
		},
		created() {
			this.getBackImage()
			let user_token = window.localStorage.getItem('user_token')
			if(!user_token){
				window.localStorage.clear();
				this.$router.push({
					path: "/homeIndex",
					query: {
						index: 1
					}
				})
			}else{
				this.getTips()
			}
			let userVal = window.localStorage.getItem('userinfo')
			this.userInfoVal = JSON.parse(userVal)
			if(this.userInfoVal){
				this.is_sign = true
			}
			if (!this.$route.query.index) {
				this.indexItem = 1
			} else {
				this.indexItem = this.$route.query.index
			}
		},
		methods: {
			getTips(){
				this.$http.getMessageTips({}).then(res=>{
					if(res.code == 1){
						let num = res.data.article.new_msg[0].num
						if(num > '0'){
							this.xiaoxitishiHidden = true
						}else{
							console.log('none');
						}
					}
				})
			},
			getBackImage(){
				this.$http.getContactUs({
					code:'user'
				}).then(res=>{
					if(res.code == 1){
						this.bgImageOne = res.data.pc_homeimage_one
					}
				})
			},
			assignment(){
				this.is_sign = true;
				let userVal = window.localStorage.getItem('userinfo')
				this.userInfoVal = JSON.parse(userVal)
			},
			logiSign() {
				this.$emit("loginTigger", "触发登录")
			},
			singOut(){
				window.localStorage.clear()
				this.is_sign = false
				this.$router.push({
					path: "/homeIndex",
					query: {
						index: 1
					}
				})
				this.$router.go(0);
				// this.$emit("loginTigger", "触发登录")
			},
			btnClick(val) {
				if(!window.localStorage.getItem('user_token')){
					this.$message.info('please log in first')
					this.$emit("loginTigger", "触发登录")
					return false
				}
				if (val == 1) {
					this.$router.push({
						path: "/homeIndex",
						query: {
							index: val
						}
					})
				} else {
					this.$router.push({
						path: "/placeOrder",
						query: {
							index: val
						}
					})
				}
			},
			// 跳转首页
			goHome() {
				if(!window.localStorage.getItem('user_token')){
					this.$message.info('please log in first')
					this.$emit("loginTigger", "触发登录")
					return false
				}
				this.$router.push("/homeIndex")
			},
			// 跳转消息
			goMessagePage() {
				if(!window.localStorage.getItem('user_token')){
					this.$message.info('please log in first')
					this.$emit("loginTigger", "触发登录")
					return false
				}
				this.$router.push({
					path: "/messagePage",
					query: {
						index: 3
					}
				})
			},
			toMineIndex() {
				if(!window.localStorage.getItem('user_token')){
					this.$message.error('please log in first')
					return false
				}
				this.$router.push({
					path: "/mineIndex",
					query: {
						index: 4
					}
				})
			}
		}
	}
</script>
<style scoped>
	@import "../assets/css/header.css";
</style>