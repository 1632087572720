<template>
	<div id="app">
		<!-- <headerPage />
		
		<footerPage /> -->
		<router-view/>
	</div>
</template>

<script>
	import headerPage from './components/headerPage.vue'
	import footerPage from './components/footerPage.vue'
	export default {
		name: 'app',
		components: {
			headerPage,
			footerPage
		},
		watch:{
			$route(){
				window.scrollTo(0,0)
			}
		}
	}
</script>

<style>
	* {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	
	.flex_center{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.flex_between{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.flex_ali{
		display: flex;
		align-items: center;
	}
	
	.flex{
		display: flex;
	}
</style>